<template>
  <v-container fluid grid-list-xs style="background-color: transparent">
    <!-- fill-height ทำให้ข้างในอยู่กลาง -->
    <v-layout row wrap full-width>
      <v-flex xs12 text-center class="py-3" style="font-size: 6vw;font-weight: bold; border-bottom: 2px pink solid; ">
        <!-- class="text-right mr-1 mt-2 mr-2 pink--text text--darken-1" -->
        รายงาน
      </v-flex>
      <v-flex xs12>
        <!-- class="text-right mr-1 mt-2 mr-2 pink--text text--darken-1" -->
        <v-list style="background-color:transparent;">
          <v-list-item-group active-class="pink--text">
            <ListCoverPage />
            <v-divider></v-divider>
            <ListHP />
            <v-divider></v-divider>
            <ListPrizePercent />
            <v-divider></v-divider>
            <ListResultPrize />
            <v-divider></v-divider>
            <ListBalance />
            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ListCoverPage from "@/components/Report/Main/List/ListCoverPage";
import ListHP from "@/components/Report/Main/List/ListHP";
import ListPrizePercent from "@/components/Report/Main/List/ListPrizePercent";
import ListResultPrize from "@/components/Report/Main/List/ListResultPrize";
import ListBalance from "@/components/Report/Main/List/ListBalance";

export default {
  components: {
    ListCoverPage,
    ListHP,
    ListPrizePercent,
    ListResultPrize,
    ListBalance,
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    CloseDT: "",
    IsTest: 0,
  }),
};
</script>

<style scoped>
/* .absolute {
  position: absolute;
  bottom: 10px;
  width: 50%;
  border: 3px solid #8ac007;
}
.top {
  background-color: azure;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1 40px
}
.content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.footer {
    flex: 0 1 45px;
    background-color: lightcyan;
    margin-bottom: 0;
    padding-bottom: 0;
} */
</style>
